if (typeof jQuery === 'undefined') {
	throw new Error('jQuery requred');
}

(function ( $ ) {

	const init = function(){
		let dictionary = {};
		let current_lang = localStorage.getItem('current_lang') || "en";
		
		/**
		 * Refresh lang
		 * @return {[type]} [description]
		 */
		const translate = function(){
			$("[data-translate]").each(function(){
			    const keys = $(this).data('translate');
			    switch ($(this)[0].nodeName) {
			    	case 'HTML':
			    		$(this).attr("lang", look(dictionary[current_lang], keys) || current_lang);
							break;
					case 'TITLE':
						$(this).text(look(dictionary[current_lang], keys) || "N/A");
						break;
			      	case 'META':
			    		$(this).attr("content", look(dictionary[current_lang], keys) || current_lang);
			        break;
			     	case 'IMG':
			     		$(this).attr('src', look(dictionary[current_lang], keys + '.src') || "N/A");
			     		$(this).attr('alt', look(dictionary[current_lang], keys + '.alt') || "N/A");
			        break;
			    	default:
			      		$(this).html(look(dictionary[current_lang], keys) || "N/A");
			        break;
			    }
			});
		};

		/**
		 * Look for string recursively
		 * @param  {array} arrDic  [description]
		 * @param  {string} sKeys  [description]
		 * @return {[type]}        [description]
		 */
		const look = function(arrDic, sKeys) {
			if (arrDic !== null && typeof arrDic !== typeof undefined) {
				let kArray = sKeys.split(/[.]/);
				if(kArray.length === 1 && typeof arrDic[kArray] !== typeof undefined && typeof arrDic[kArray] === typeof ''){
					return arrDic[kArray[0]];
				} else {
					for (let key of kArray) {
					    // check if the property/key is defined in the object itself, not in parent
					    if (arrDic[key] !== null && typeof arrDic[key] !== typeof undefined) {
					        if(typeof arrDic[key] === typeof ''){
					        	return arrDic[key];
					        } else {
					        	return look(arrDic[key], kArray.filter((item) => item !== key).join('.'));
					        }
					    } else {
					    	return;
					    }
					}
				}
			} else {
		    	return;
		    }
		}

		/**
		 * Set Language
		 * @param {String} lang [description]
		 */
		const setLang = function(lang = 'en') {
			current_lang = lang;
			localStorage.setItem('current_lang', lang);
			translate();
		}
		
		/**
		 * FAQ Accordion icon
		 * @param  {[type]} e [description]
		 * @return {[type]}   [description]
		 */
		$("#accordionFaq").on("hide.bs.collapse show.bs.collapse", e => {
			if ($(e.target).prev().find("img:last-child").attr('src') === 'img/chevron-arrow-down.svg') {
				$(e.target)
				    .prev()
				    .find("img:last-child")
				    .attr('src', 'img/chevron-arrow-up.svg');
			} else {
				$(e.target)
				    .prev()
				    .find("img:last-child")
				    .attr('src', 'img/chevron-arrow-down.svg');
			}
		  
		});

		/**
		 * Load EN dicc
		 * @param  {[type]} text){				dictionary.en [description]
		 * @return {[type]}                          [description]
		 */
		const en = $.ajax("i18n/en.json").done(function(text){
			//  Parse it
			dictionary.en = JSON.parse(JSON.stringify(text));
			// console.log(dictionary[current_lang]);
		}).fail(function (jqXHR, textStatus) {
		    console.log(textStatus, 'Error loading en dicitionary');
		});

		/**
		 * Load FR dicc
		 * @param  {[type]} text){				dictionary.fr [description]
		 * @return {[type]}                          [description]
		 */
		const fr = $.ajax("i18n/fr.json").done(function(text){
			//  Parse it
			dictionary.fr = JSON.parse(JSON.stringify(text));
		}).fail(function (jqXHR, textStatus) {
		    console.log(textStatus, 'Error loading fr dictionary');
		});
		
		/**
		 * Initial lang set
		 * @param  {[type]} responseEn [description]
		 * @param  {[type]} responseFr 
		 * @return {[type]}            [description]
		 */
		$.when(en, fr).then(function( responseEn, responseFr ) {
		   	console.log('Dictionaries loaded!');
		   	// Change lang on click
		   	$("[data-lang]").each(function(e){
		       	$(this).on('click', function(e){
		       		setLang($(this).data('lang'));
		       		$(this).data('lang') === 'en' ? $(this).data('lang', 'fr') : $(this).data('lang', 'en');
		       		// Update Cookie Law lang
		       		return false;
		       	});
		       
		   	});

		   	getLangFromUrl();
		   
		   	/**
		    * Cookie consent
		    * @type {String}
		    */
			$.cookieBar('addTranslation', 'en', dictionary.en.cookie);
		   	$.cookieBar('addTranslation', 'fr', dictionary.fr.cookie);

		   	let opt = {
		   		style: 'bottom',
		   		infoLink: current_lang === 'fr' ? dictionary.fr.cookie.infoLink : 'https://serta.ca/privacy-policy',
		   		language: current_lang || 'en'
		   	};

		   	$.cookieBar(opt);
		});

		/**
		 * Check if lang comes in URL
		 * @return {[type]} [description]
		 */
		const getLangFromUrl = function() {
			let pathArray = window.location.pathname.split('/');
			setLang(pathArray[1] || current_lang);
		}

		

		/**
		 * Scroll Snap
		 */
		cssScrollSnapPolyfill();

		// fix for Scroll-Snap on Chrome
		const scrollFix = function() {
			document.body.classList.add('fix');
	    	setTimeout(function() { document.body.classList.remove('fix'); }, 0);
		};

	    $(window).resize(function(){
	     	scrollFix();
	    });

	    scrollFix();

	}

	init();
}( jQuery ));